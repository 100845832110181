// 申遗承诺
import SHZT from './sn-table-column-shzt';

const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [
                {
                    prop: 'HDMC',
                    label: '活动名称',
                    minWidth: 135
                }, {
                    prop: 'HDLX_DESC',
                    label: '活动类型',
                    minWidth: 105,
                    showoverflowtooltip: true
                }, {
                    prop: 'ZZBM',
                    label: '组织部门',
                    minWidth: 128,
                }, {
                    prop: 'HDSJ',
                    label: '活动时间',
                    minWidth: 160,
                    showoverflowtooltip: true
                }, {
                    prop: 'YXPG_DESC',
                    label: '影响评估',
                    minWidth: 130,
                }, {
                    prop: 'LJ',
                    label: '文件',
                    minWidth: 130,
                    showFileBtn: true,
                },
                SHZT,
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
    },
    snButton: {
        buttonData: [
            {
                isShow: true,
                btnType: 'button',
                operateType: 'buttonNew',
                showMethod: 'showNewBtn',
                name: '新建',
                round: true,
                backColor: '#28ccd9',
            },
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    }
};
export default selfData;